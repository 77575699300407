import { Box } from "@mui/material";
import { useEffect } from "react";
import { Datagrid, FunctionField, List, ReferenceField, SelectInput, ShowButton, TextField, TextInput, useStore } from "react-admin";
import { CityEnum, CityIdMapping } from "../../enums/CityEnum";
import { useAppSelector } from "../../store/hooks";
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";

const filters = [
    <TextInput label="Search by phone" source="mobile" alwaysOn />,
    <SelectInput
        label="City"
        source="cityId"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(CityEnum)
                .map(key => {
                    return {
                        id: CityIdMapping[key],
                        name: key
                    }
                })
        }
    />,
];

const HouseList = () => {
    const isCustomSelectOpen = useAppSelector((state) => state.customSelect.value.open);
    const [, setUserBundlesForHouse] = useStore<boolean>('customerTabs_housesForCustomer', false)
    useEffect(() => setUserBundlesForHouse(false))
    return (
        <List filters={filters}>
            <Datagrid bulkActionButtons={false} rowStyle={(record, index) => {
                return {
                    backgroundColor:
                    record.hasActiveSubscription ? "#ADD8E3" : "",
                    color: record.hasActiveSubscription ? "#ffffff" : "",
                };
                }}>
                {/*Will be active if custom popup is open*/}
                {
                    isCustomSelectOpen?
                    <ResourceSelectDialogButton />: <ShowButton />
                }
                <TextField source="id" sortable={false} />
                <TextField source="hasActiveSubscription" label='Active Subscription' sortable={false} />
                <FunctionField
                    emptyText=""
                    label="Address"
                    render={(record: { address: any; cityName: any; localityName: any; areaName: any;
                    }) =>
                        <Box minWidth="200px">
                            {[record?.address, record?.localityName, record?.areaName, record?.cityName]?.filter((each) => each !== null).join(', ')}
                        </Box>
                    }
                />
                {/* <FunctionField label="address" render={(record: any) => <Box minWidth="200px">{record?.address}</Box>} /> */}
                <TextField source="locality" sortable={false} />
                <ReferenceField source="cityId" link="show" reference="Cities">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="areaId" link="show" reference="Areas">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="localityId" link="show" reference="Localities">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="mobile" sortable={false} />
                <TextField source="city" sortable={false} />
                <TextField source="sector" sortable={false} />
                <TextField source="lat" sortable={false} />
                <TextField source="long" sortable={false} />
                {/*Fields which will be not shown when custom popup is open goes here*/}
            </Datagrid>
        </List>
    )
};

export default HouseList;
