import { Autocomplete, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ArrayField, SimpleShowLayout, useRefresh, useShowContext } from "react-admin";
import ChefForPartyService from "../../services/ChefForPartyService";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { Close } from "@mui/icons-material";

import ChefForPartyDish from "./ChefForPartyDish";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import { MealType } from "../../enums/MealType";

const chefForPartyService = new ChefForPartyService();

interface InputProps {
    dishes: any;
    setDishes: (dishes: any) => void;
    setAllDishes: (dishes: number[]) => void;
    allDishes: any;
    mealType: MealType
}

const DishList = (props: InputProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [category, setCategory] = useState([]);
    const [selectCategory, setSelectCategory] = useState<any>(null);
    const [allDishes, setAllDishes] = useState<any>(null);
    
    


    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [previousDish, setPreviousDish] = useState([]);



    const [allDishesInCategory, setAllDishesInCategory] = useState<any>([]);
    const [selectedDish, setSelectedDish] = useState<any>(null);

    const refresh = useRefresh();


    const { record } = useShowContext();


    function editDish(dishId: number) {
        setPreviousDish(props.allDishes.filter((dish: number) => dish !== dishId));
        setEditOpen(true);
    }

    function handleClose() {
        setOpen(false);
        setSelectedDish(null);
    }
    function handleOpen(mealType: string) {
        if (mealType === "STARTER" && record?.noOfStarters <= props?.dishes.length) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Can't add more Items in Starters",
                type: CustomSnackbarTypes.ERROR,
            });
            return;
        }
        else if (mealType === "MAIN COURSE" && record?.noOfMainCourse <= props?.dishes.length) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Can't add more Items in Main Course",
                type: CustomSnackbarTypes.ERROR,
            });
            return;
        }
        if (mealType === "DESSERT" && record?.noOfDesserts <= props?.dishes.length) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Can't add more Items in Desserts",
                type: CustomSnackbarTypes.ERROR,
            });
            return;
        }
        if (mealType === "SIDE_DISH" && record?.noOfSideDishes <= props?.dishes.length) {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Can't add more Items in Side Dish",
                type: CustomSnackbarTypes.ERROR,
            });
            return;
        }
        setOpen(true);
    }

    function removeDishes(dishId: number) {
        chefForPartyService
            .addDIshChefForParty(Number(record.id), { dishIds: props.allDishes.filter((dish: number) => Number(dish) !== dishId) })
            .then((res) => {
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Removed Successfully",
                    type: CustomSnackbarTypes.SUCCESS,
                });
                refresh();
                handleClose();
            });
        props.setAllDishes(props.allDishes.filter((dish: number) => Number(dish) !== dishId));
        props.setDishes(props.dishes.filter((dish: any) => Number(dish.dish.id) !== dishId));

    }
    useEffect(() => {
        if (!open) return;
        chefForPartyService
            .getAllCategory()
            .then((data: any) => setCategory(data))
            .catch((err) => setCategory([]));
        chefForPartyService.getAllDishes()
            .then((data: any)=> setAllDishes(data?.data))
            .catch((err) => setAllDishes([]));    
    }, [open]);

    useEffect(() => {
        if (!editOpen) return;
        chefForPartyService
            .getAllCategory()
            .then((data: any) => setCategory(data))
            .catch((err) => setCategory([]));
        chefForPartyService.getAllDishes()
            .then((data: any)=> setAllDishes(data?.data))
            .catch((err) => setAllDishes([]));      
    }, [editOpen]);

    useEffect(() => {
        if (selectCategory) {
            chefForPartyService
                .getAllDishesForTypeAndCategory(selectCategory, props.mealType)
                .then((data) => setAllDishesInCategory(data))
                .catch((err) => setAllDishesInCategory([]));
        }
        else{
            setAllDishesInCategory(allDishes);
        }
    }, [selectCategory, props.mealType,allDishes])



    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <div>{props.mealType}</div>
                <Button onClick={() => handleOpen(props.dishes[0]?.dish?.mealType)}>Add Dish</Button>
            </Box>
            {
                props?.dishes.length > 0 ? <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    gap={1}
                >
                    {
                        props?.dishes.map((dish: any) => (
                            <Grid item xs={3.5}>
                                <ChefForPartyDish dish={dish} removeDishes={removeDishes} editDish={editDish} />
                            </Grid>
                        ))
                    }
                </Grid> :
                    <EmptyListPlaceholder message="No Item Added" />
            }

            <Dialog
                onClose={handleClose}
                open={open}
                scroll={"paper"}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Add Dish</DialogTitle>
                <DialogContent>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
                    <FormControl style={{ width: "180px", marginBottom: "20px" }}>
                        <InputLabel id="type">{"Cuisine"}</InputLabel>
                        <Select
                            labelId="Cuisine"
                            id="Cuisine"
                            label="Cuisine"
                            defaultValue={''}
                            onChange={(event: any) => {
                                setSelectCategory(event.target.value);
                            }}
                        >
                            {category?.map((d: any) => (
                                <MenuItem key={d.id} value={d.id}>
                                    {d.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl style={{ width: "180px", marginBottom: "20px", marginLeft: '20px' }}>
                        <Autocomplete
                            options={allDishesInCategory?.filter((dish:any)=>dish?.mealType===props.mealType) || []}
                            getOptionLabel={(option: any) => option.name || ''}
                            onChange={(event, newValue) => {
                                setSelectedDish(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Dish Name"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Select a Dish"
                                />
                            )}
                            style={{ width: 200, marginBottom: "20px", marginLeft: '20px' }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions style={{ marginTop: "12px" }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (!selectedDish) {
                                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                    message: "Select a dish to continue",
                                    type: CustomSnackbarTypes.WARNING,
                                });
                                return;
                            }
                            chefForPartyService
                                .addDIshChefForParty(Number(record?.id), { dishIds: [...(props.allDishes), selectedDish?.id] })
                                .then((res) => {
                                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                        message: "Added Successfully",
                                        type: CustomSnackbarTypes.SUCCESS,
                                    });
                                    props.setAllDishes([...(props.allDishes), selectedDish?.id]);
                                    props.setDishes([...(props.dishes), selectedDish])
                                    refresh();
                                    handleClose();
                                });
                        }}
                        size={"large"}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color={"error"}
                        onClick={handleClose}
                        size={"large"}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                onClose={handleClose}
                open={editOpen}
                scroll={"paper"}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Edit Dish</DialogTitle>
                <DialogContent>
                    <IconButton onClick={() => { setEditOpen(false); setSelectedDish(null) }} sx={{ position: 'absolute', top: 2, right: 5 }}> <Close /> </IconButton>
                    <FormControl style={{ width: "180px", marginBottom: "20px" }}>
                        <InputLabel id="type">{"Cuisine"}</InputLabel>
                        <Select
                            labelId="Cuisine"
                            id="Cuisine"
                            label="Cuisine"
                            defaultValue={''}
                            onChange={(event: any) => {
                                setSelectCategory(event.target.value);
                                setSelectedDish(null);
                            }}
                        >
                            {category?.map((d: any) => (
                                <MenuItem key={d.id} value={d.id}>
                                    {d.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl style={{ width: "180px", marginBottom: "20px", marginLeft: '20px' }}>
                        <Autocomplete
                            options={allDishesInCategory?.filter((dish:any)=>dish?.mealType===props.mealType) || []}
                            getOptionLabel={(option: any) => option.name || ''}
                            onChange={(event, newValue) => {
                                setSelectedDish(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Dish Name"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Select a Dish"
                                />
                            )}
                            style={{ width: 300, marginBottom: "20px", marginLeft: '20px' }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions style={{ marginTop: "12px" }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (!selectedDish) {
                                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                    message: "Select a dish to continue",
                                    type: CustomSnackbarTypes.WARNING,
                                });
                                return;
                            }
                            chefForPartyService
                                .addDIshChefForParty(Number(record?.id), { dishIds: [...previousDish, selectedDish?.id] })
                                .then((res) => {
                                    EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                        message: "Added Successfully",
                                        type: CustomSnackbarTypes.SUCCESS,
                                    });
                                    refresh();
                                    setEditOpen(false);
                                    setSelectedDish(null);
                                    setPreviousDish([]);
                                });
                        }}
                        size={"large"}
                    >
                        Submit
                    </Button>
                    <Button
                        variant="contained"
                        color={"error"}
                        onClick={() => { setEditOpen(false); setSelectedDish(null) }}
                        size={"large"}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}

const ChefForPartyOrderListEdit = () => {
    const [starters, setStarters] = useState<any>(null);
    const [mainCourse, setMainCourse] = useState<any>(null);
    const [desserts, setDesserts] = useState<any>(null);
    const [sideDish, setSide] = useState<any>(null);
    const [allDishes, setAllDishes] = useState<number[]>([])

    const { record } = useShowContext();

    useEffect(() => {
        setStarters(record?.dishes.filter((dish: any) => dish?.dish?.mealType === "STARTER"));
        setMainCourse(record?.dishes.filter((dish: any) => dish?.dish?.mealType === "MAIN COURSE"));
        setDesserts(record?.dishes.filter((dish: any) => dish?.dish?.mealType === "DESSERT"));
        setSide(record?.dishes.filter((dish: any) => dish?.dish?.mealType === "SIDE_DISH"));
        setAllDishes(record?.dishes.map((dish: any) => dish?.dish?.id));
    }, [starters, mainCourse, desserts, sideDish, record?.dishes]);




    return (
        <>
            {
                record?.noOfStarters > 0 && <Card sx={{ marginTop: "1.5rem" }}>
                    <SimpleShowLayout>
                        <ArrayField emptyText="Not Available">
                            {
                                starters && allDishes && <DishList dishes={starters} setDishes={setStarters} setAllDishes={setAllDishes} allDishes={allDishes} mealType={MealType.STARTER} />
                            }
                        </ArrayField>
                    </SimpleShowLayout>
                </Card>
            }
            {
                record?.noOfMainCourse > 0 && <Card sx={{ marginTop: "1.5rem" }}>
                    <SimpleShowLayout>
                        <ArrayField emptyText="Not Available">
                            {
                                mainCourse && allDishes && <DishList dishes={mainCourse} setDishes={setMainCourse} setAllDishes={setAllDishes} allDishes={allDishes} mealType={MealType.MAIN_COURSE} />
                            }
                        </ArrayField>
                    </SimpleShowLayout>
                </Card>
            }
            {
                record?.noOfDesserts > 0 && <Card sx={{ marginTop: "1.5rem" }}>
                    <SimpleShowLayout>
                        <ArrayField emptyText="Not Available">
                            {
                                desserts && allDishes && <DishList dishes={desserts} setDishes={setDesserts} setAllDishes={setAllDishes} allDishes={allDishes} mealType={MealType.DESSERT} />
                            }
                        </ArrayField>
                    </SimpleShowLayout>
                </Card>
            }

            {
                record?.noOfSideDishes > 0 && <Card sx={{ marginTop: "1.5rem" }}>
                    <SimpleShowLayout>
                        <ArrayField emptyText="Not Available">
                            {
                                sideDish && allDishes && <DishList dishes={sideDish} setDishes={setSide} setAllDishes={setAllDishes} allDishes={allDishes} mealType={MealType.SIDE_DISH} />
                            }
                        </ArrayField>
                    </SimpleShowLayout>
                </Card>
            }

        </>
    )

}

export default ChefForPartyOrderListEdit;