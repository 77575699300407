import {Button} from '@mui/material';
import {Show, Tab, TabbedShowLayout, useRefresh, useShowContext} from 'react-admin';
import AreaService from '../../services/AreaService';
import AreaMetaShow from "./AreaMetaShow";
import AreaServicesTab from "./AreaServicesTab";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {useAppSelector} from "../../store/hooks";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import {UserGroupsEnum} from "../../enums/UserGroupsEnum";
import {useState} from "react";
import EditLatLongLocalityDialog from "../Common/EditLatLongLocalityDialog";

const areaService = new AreaService();

const CTAButtons = (props: any) => {
    const userDetails = useAppSelector((state) => state.userDetails.value);
    let groupsArray: string[] = userDetails.adminUser.accessGroups;
    const { record } = useShowContext();
    const refresh = useRefresh();

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 10,
                margin: 6,
            }}
        >
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                    props.setSelectedData(record);
                    props.setOpenDialog(true);
                }}
            >
                {'Edit Coordinates'}
            </Button>
            <Button
                variant={"contained"}
                size="small"
                color={record?.hasPayAfterService ? 'error' : 'primary'}
                disabled={!GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER])}
                onClick={() => {
                    EventBus.emitEvent(
                        EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                        {
                            title: "Are you sure?",
                            message: "Please confirm to change the pay after service availability",
                            successLabel: "Confirm",
                            failureLabel: "Cancel",
                            onClose: (confirmed: boolean) => {
                                if (confirmed) {
                                    areaService.markPayAfterService(Number(record.id), {hasPayAfterService: !record?.hasPayAfterService})
                                        .then(() => refresh());
                                }
                            }
                        }
                    );
                }}
                disableElevation>
                {record?.hasPayAfterService ? 'Disable Pay after service' : 'Enable Pay after service'}
            </Button>
           {!record?.isServiceable ? <Button
                variant={"contained"}
                size="small"
                color="primary"
                disabled={!GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER])}
                onClick={() => {
                    EventBus.emitEvent(
                        EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                        {
                            title: "Are you sure?",
                            message: "Please confirm to change the serviceability",
                            successLabel: "Confirm",
                            failureLabel: "Cancel",
                            onClose: (confirmed: boolean) => {
                                if (confirmed) {
                                    areaService.markAsServiceable(Number(record.id))
                                        .then(() => refresh());
                                }
                            }
                        }
                    );
                }}
                disableElevation>
                Mark as serviceable
            </Button> :
            <Button
                variant={"contained"}
                size="small"
                color="error"
                disabled={!GroupPermissionFunction(groupsArray, [UserGroupsEnum.ADMIN, UserGroupsEnum.MANAGER])}
                onClick={() => {
                    EventBus.emitEvent(
                        EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
                        {
                            title: "Are you sure?",
                            message: "Please confirm to change the serviceability",
                            successLabel: "Confirm",
                            failureLabel: "Cancel",
                            onClose: (confirmed: boolean) => {
                                if (confirmed) {
                                    areaService.markAsUnServiceable(Number(record.id))
                                        .then(() => refresh())
                                }
                            }
                        }
                    );
                }}
                disableElevation>
                Mark as unserviceable
            </Button>}
        </div>
    )
}

const AreaTab = () => {
    return(
        <TabbedShowLayout syncWithLocation={true}>
            <Tab label={"Details"} key={"Details"}>
                <AreaMetaShow />
            </Tab>
            <Tab label={"Services"} key={"Services"}>
                <AreaServicesTab />
            </Tab>
        </TabbedShowLayout>
    )
};

function AreaShow(props: any) {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>(null);

    return (
        <>
            <EditLatLongLocalityDialog
                open={openDialog}
                setOpen={setOpenDialog}
                data={selectedData}
                type={'AREA'}
            />
            <Show actions={false}>
                <CTAButtons
                    setSelectedData={setSelectedData}
                    setOpenDialog={setOpenDialog}
                />
                <AreaTab />
            </Show>
        </>
    )
}

export default AreaShow;
