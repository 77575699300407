import {
  Autocomplete,
  Box,
  Button, Checkbox,
  Chip,
  FormControl, FormGroup,
  FormHelperText, FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput, RadioGroup,
  Select,
  styled,
  TextField,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import CkMuiDialog from "./CkMuiDialog/CkMuiDialog";
import {
  CookProfileGender,
  CovidVaccinationStatus,
  Languages,
  Religion,
  TshirtNumberMapping,
  TshirtSizeEnum,
  YesNoEnum,
} from "../../enums/common";
import ModeOfTravel from "../../enums/ModeOfTravelEnum";
import { CookService } from "../../services/CookServices";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import FreshLeadInterface from "../../interfaces/Cook/FreshLeadInterface";
import { UpdateCookProfileDto } from "../../dtos/UpdateCookProfileDto";
import { NewVendorSource } from "../../enums/NewVendorSource";
import newVendorSubResource from "../../utils/NewVendorSubResource";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import numberToShirtSizeMapping from "../../utils/NumberToShirtSizeMapping";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import PreviousWorkSelect from "./PreviousWorkSelect";
import CurrentCitySelect from "./CurrentCitySelect";
import CityService from "../../services/CityService";


const cookService = new CookService();
const cityService =new CityService();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Input = styled("input")({
  display: "none",
});

interface InputProps {
  cookDetail: FreshLeadInterface;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isFirstTimeRequest: boolean;
  refresh: () => void;
}

interface FileNames {
  profilePhoto: string;
  aadharFrontPhoto: string;
  aadharBackPhoto: string;
}

const UpdateCookDetailsDialog = ({
  cookDetail,
  isOpen,
  setIsOpen,
  isFirstTimeRequest,
  refresh,
}: InputProps) => {
  const [cookDetails, setCookDetails] = useState<any>(cookDetail);
 console.log(cookDetails?.preferredCities);
  const [fileNames, setFileNames] = useState<FileNames>({
    profilePhoto: "",
    aadharBackPhoto: "",
    aadharFrontPhoto: "",
  });
  
  const [changeState, setChangeState] = useState<any>();
  const [cities,setCities] = useState<any>([]);
  const handleCheckCuisines = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.checked, e.target.value, cookDetails?.cuisinesCanCook)
    let data: string[] = cookDetails?.cuisinesCanCook || [];
    if(e.target.checked) {
      data.push(e.target.value);
    } else {
      data = data.filter((each: string) => each !== e.target.value);
    }
    setCookDetails({
      ...cookDetails,
      cuisinesCanCook: data,
    });
    setChangeState({
      ...changeState,
      cuisinesCanCook: data,
    });
  };

  const validateForm = (cookChandedDetails: UpdateCookProfileDto) => {
    var regExp = /[a-zA-Z]/g;
    if (
      cookChandedDetails?.referralMobileNumber &&
      (cookChandedDetails?.referralMobileNumber.length !== 10 ||
        regExp.test(cookChandedDetails?.referralMobileNumber))
    ) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Referral Mobile Number Not Valid",
        type: CustomSnackbarTypes.ERROR,
      });
      return false;
    }
    if (
      cookChandedDetails?.aadharNumber &&
      (cookChandedDetails?.aadharNumber.length !== 12 ||
        regExp.test(cookChandedDetails?.aadharNumber))
    ) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Aadhar Number Not Valid",
        type: CustomSnackbarTypes.ERROR,
      });
      return false;
    }
    if (
      cookDetails?.permanentAddressPinCode &&
      (cookDetails?.permanentAddressPinCode.length !== 6 ||
        regExp.test(cookDetails?.permanentAddressPinCode))
    ) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Permanent Pincode Not Valid",
        type: CustomSnackbarTypes.ERROR,
      });
      return false;
    }
    if (
      cookDetails?.currentAddressPinCode &&
      (cookDetails?.currentAddressPinCode.length !== 6 ||
        regExp.test(cookDetails?.currentAddressPinCode))
    ) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Current Pincode Not Valid",
        type: CustomSnackbarTypes.ERROR,
      });
      return false;
    }
    if (
      cookDetails?.emergencyContactNumber &&
      (cookDetails?.emergencyContactNumber.length !== 10 ||
        regExp.test(cookDetails?.emergencyContactNumber))
    ) {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: "Emergency Contact Number Not Valid",
        type: CustomSnackbarTypes.ERROR,
      });
      return false;
    }
    return true;
  };

  const handleDetailsChange = (e: any) => {
    let newObj = {
      ...changeState,
      [e.target.name]: e.target.value,
    };
    if (e.target.name === "source") {
      setCookDetails({
        ...cookDetails,
        [e.target.name]: e.target.value,
        subSource: null,
      });
      setChangeState({
        ...newObj,
        [e.target.name]: e.target.value,
        subSource: null,
      });
    } else if (
      e.target.name === "hasSmartphone" ||
      e.target.name === "interestedInHouseholdJobs" ||
      e.target.name === "isMgCook" ||
      e.target.name === "cooksNonVeg"
    ) {
      setCookDetails({
        ...cookDetails,
        [e.target.name]: e.target.value,
      });
      setChangeState({
        ...newObj,
        [e.target.name]: e.target.value === YesNoEnum.YES ? true : false,
      });
    } else {
      setCookDetails({ ...cookDetails, [e.target.name]: e.target.value });
      setChangeState(newObj);
    }
  };

  const handleSubmit = async () => {
    const refactoredCookDetails: UpdateCookProfileDto = changeState;
    if (!validateForm(refactoredCookDetails)) {
      return;
    }
    if(changeState?.hubId) {
      await cookService.updateHub(cookDetail?.cookId, {hubId: changeState?.hubId}).then(() => {});
      delete changeState?.hubId;
    }
    if (isFirstTimeRequest) {
      await cookService
        .addCookProfile(cookDetails?.cookId, refactoredCookDetails)
        .then((res) => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Profile Updated",
            type: CustomSnackbarTypes.SUCCESS,
          });
        })
        .catch((err) => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Something Went Wrong",
            type: CustomSnackbarTypes.ERROR,
          });
        })
        .finally(() => {
          setChangeState({});
          refresh();
        });
    } else {
      await cookService
        .editCookProfile(cookDetails?.cookId, refactoredCookDetails)
        .then((res) => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Profile Updated",
            type: CustomSnackbarTypes.SUCCESS,
          });
        })
        .catch((err) => {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Something Went Wrong",
            type: CustomSnackbarTypes.ERROR,
          });
        })
        .finally(() => {
          setChangeState({});
          refresh();
        });
    }
    setChangeState({});
    setIsOpen(false);
  };

  const onImageSelect = (event: any) => {
    const image = event.target.files[0];
    const requestBy = event.target.name;
    cookService
      .getAWSLinks(requestBy, `${cookDetail?.id}-${image?.name}`)
      .then((res) => {
        const updatedCookDetails: UpdateCookProfileDto = {
          ...cookDetails,
          [requestBy]: res?.getUrl,
        };
        setChangeState({ ...changeState, [requestBy]: res?.getUrl });
        setCookDetails(updatedCookDetails);
        setFileNames({ ...fileNames, [requestBy]: image?.name });
        cookService
          .uploadImageToAWS(res?.putUrl, image)
          .then((res) => {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              message: "Image uploaded successfully",
              type: CustomSnackbarTypes.SUCCESS,
            });
          })
          .catch((err) => {
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
              message: "Something Went Wrong",
              type: CustomSnackbarTypes.ERROR,
            });
          });
      })
      .catch((err) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Something Went Wrong",
          type: CustomSnackbarTypes.ERROR,
        });
      });
  };

  const GetSubSource = (props: any) => {
    if (
      props.source === NewVendorSource.ONLINE ||
      props.source === NewVendorSource.OFFLINE
    ) {
      return (
        <FormControl sx={{ minWidth: 210 }}>
          <InputLabel id="demo-simple-select-label">
            Subsource of Lead
          </InputLabel>
          <Select
            name="subSource"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={cookDetails?.subSource || cookDetails.subSource}
            label="Subsource of Lead"
            onChange={handleDetailsChange}
          >
            {newVendorSubResource(props.source).map((e: any) => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    return null;
  };

  useEffect(() => {
    cityService.fetchAllCities(100, 0, {isCurrentlyServing:"YES"}, {}).then((res) => {
      setCities(res?.data || []);
    });
  }, []);
  

  return (
    <div>
      <CkMuiDialog
        isDialogOpen={isOpen}
        setDialogOpen={() => {}}
        onDialogClose={() => {}}
        title={"Update Details"}
        primaryLabel={"Submit"}
        primaryAction={handleSubmit}
        secondaryLabel={"Close"}
        secondaryAction={() => {
          setIsOpen(false);
        }}
        isLoading={false}
        fullWidth={true}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {`Cook id: ${cookDetail?.id}`}
          </Grid>
          <Grid item xs={6}>
          <FormControl style={{ width: 210 }} fullWidth required>
            <TextField
              name="firstName"
              sx={{ minWidth: 210 }}
              id="outlined-basic"
              label="First Name"
              required
              variant="outlined"
              onChange={handleDetailsChange}
              value={cookDetails?.firstName}
              />
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="lastName"
              sx={{ minWidth: 210 }}
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              onChange={handleDetailsChange}
              value={cookDetails?.lastName}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: 210 }} fullWidth required>
              <DatePicker
                views={["year"]}
                label="Cooking Since"
                maxDate={moment().toDate()}
                value={cookDetails?.cookingSince || null}
                onChange={(value) => {
                  setCookDetails({
                    ...cookDetails,
                    cookingSince: moment(value).format("YYYY"),
                  });
                  setChangeState({
                    ...changeState,
                    cookingSince: moment(value).format("YYYY"),
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-label">
                Cooks Non Veg?
              </InputLabel>
              <Select
                name="cooksNonVeg"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Cooks Non Veg?"
                onChange={handleDetailsChange}
                defaultValue={
                  cookDetails?.cooksNonVeg !== null
                    ? cookDetails?.cooksNonVeg === true
                      ? YesNoEnum.YES
                      : YesNoEnum.NO
                    : undefined
                }
              >
                {Object.values(YesNoEnum).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>

          {/*<Grid item xs={6}>*/}
          {/*  <FormControl sx={{ width: 210 }}>*/}
          {/*    <InputLabel id="demo-multiple-chip-label" required>*/}
          {/*      Cuisine Known*/}
          {/*    </InputLabel>*/}
          {/*    <Select*/}
          {/*      name="cuisineKnown"*/}
          {/*      labelId="demo-multiple-chip-label"*/}
          {/*      id="demo-multiple-chip"*/}
          {/*      multiple*/}
          {/*      value={cookDetails?.cuisineKnown || []}*/}
          {/*      onChange={handleDetailsChange}*/}
          {/*      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}*/}
          {/*      renderValue={(selected) => (*/}
          {/*        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>*/}
          {/*          {selected.map((value: any) => (*/}
          {/*            <Chip*/}
          {/*              key={value}*/}
          {/*              label={value}*/}
          {/*              clickable*/}
          {/*              deleteIcon={*/}
          {/*                <Cancel*/}
          {/*                  onMouseDown={(e) => {*/}
          {/*                    e.stopPropagation();*/}
          {/*                    setCookDetails({*/}
          {/*                      ...cookDetails,*/}
          {/*                      cuisineKnown: cookDetails.cuisineKnown.filter(*/}
          {/*                        (cuisine: any) => cuisine !== value*/}
          {/*                      ),*/}
          {/*                    });*/}
          {/*                    setChangeState({*/}
          {/*                      ...changeState,*/}
          {/*                      cuisineKnown: cookDetails.cuisineKnown.filter(*/}
          {/*                        (cuisine: any) => cuisine !== value*/}
          {/*                      ),*/}
          {/*                    });*/}
          {/*                  }}*/}
          {/*                />*/}
          {/*              }*/}
          {/*              onDelete={() => {}}*/}
          {/*            />*/}
          {/*          ))}*/}
          {/*        </Box>*/}
          {/*      )}*/}
          {/*      MenuProps={MenuProps}*/}
          {/*    >*/}
          {/*      {Object.keys(CuisineSkillsEnum).map((cuisine) => (*/}
          {/*        <MenuItem key={cuisine} value={cuisine}>*/}
          {/*          {cuisine}*/}
          {/*        </MenuItem>*/}
          {/*      ))}*/}
          {/*    </Select>*/}
          {/*    <FormHelperText>Required</FormHelperText>*/}
          {/*  </FormControl>*/}
          {/*</Grid>*/}

          <Grid item xs={6}>
            <FormControl sx={{ width: 210 }}>
              <InputLabel id="demo-multiple-chip-label" required>
                Language
              </InputLabel>
              <Select
                name="languages"
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={cookDetails?.languages || []}
                onChange={handleDetailsChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value: any) => (
                      <Chip
                        key={value}
                        label={value}
                        clickable
                        deleteIcon={
                          <Cancel
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              setCookDetails({
                                ...cookDetails,
                                languages: cookDetails.languages.filter(
                                  (lang: any) => lang !== value
                                ),
                              });
                              setChangeState({
                                ...changeState,
                                languages: cookDetails.languages.filter(
                                  (lang: any) => lang !== value
                                ),
                              });
                            }}
                          />
                        }
                        onDelete={() => {}}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Object.values(Languages).map((language) => (
                  <MenuItem key={language} value={language}>
                    {language}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-label">
                Vaccination Status
              </InputLabel>
              <Select
                name="vaccinationStatus"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cookDetails?.vaccinationStatus}
                label="Vaccination Status"
                onChange={handleDetailsChange}
              >
                {Object.entries(CovidVaccinationStatus).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-label" required>
                Gender
              </InputLabel>
              <Select
                name="gender"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cookDetails?.gender}
                label="Gender"
                onChange={handleDetailsChange}
              >
                {Object.values(CookProfileGender).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-label" required>
                Mode of Travel
              </InputLabel>
              <Select
                name="modeOfTravel"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cookDetails?.modeOfTravel}
                label="Mode of Travel"
                onChange={handleDetailsChange}
              >
                {Object.values(ModeOfTravel).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-label">
                Religion
              </InputLabel>
              <Select
                name="religion"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cookDetails?.religion}
                label="Religion"
                onChange={handleDetailsChange}
              >
                {Object.values(Religion).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-label" required>
                Interested in household jobs?
              </InputLabel>
              <Select
                name="interestedInHouseholdJobs"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Interested in household jobs?"
                onChange={handleDetailsChange}
                defaultValue={
                  cookDetails?.interestedInHouseholdJobs !== null
                    ? cookDetails?.interestedInHouseholdJobs === true
                      ? YesNoEnum.YES
                      : YesNoEnum.NO
                    : undefined
                }
              >
                {Object.values(YesNoEnum).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="yearsOfExperienceHousehold"
              sx={{ minWidth: 210 }}
              id="outlined-basic"
              label="Household Work Experience"
              variant="outlined"
              onChange={handleDetailsChange}
              inputProps={{
                min: 0,
                max: 50,
              }}
              type="number"
              value={cookDetails?.yearsOfExperienceHousehold}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-label">
                Has Smarthpone
              </InputLabel>
              <Select
                name="hasSmartphone"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Has Smarthpone?"
                onChange={handleDetailsChange}
                defaultValue={
                  cookDetails?.hasSmartphone !== null
                    ? cookDetails?.hasSmartphone === true
                      ? YesNoEnum.YES
                      : YesNoEnum.NO
                    : undefined
                }
              >
                {Object.values(YesNoEnum).map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 210 }}>
              <label>Profile Photo {cookDetails?.profilePhoto ? "(Already Uploaded)" : "(Not available)"}</label>
              <label htmlFor="profilePhoto">
                <Button size="small" component="span" variant="contained">
                  Choose file{" "}
                  {fileNames?.profilePhoto
                    ? `: ${fileNames?.profilePhoto}`
                    : ".."}
                </Button>
                <Input
                  accept="image/*"
                  onChange={onImageSelect}
                  name="profilePhoto"
                  id="profilePhoto"
                  type="file"
                />
              </label>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 210 }}>
              <TextField
                name="aadharNumber"
                sx={{ minWidth: 210 }}
                id="outlined-basic"
                label="Aadhar Number"
                variant="outlined"
                onChange={handleDetailsChange}
                value={cookDetails?.aadharNumber}
                required
                />
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 210 }}>
              <label>Aadhar Front Photo</label>
              <label htmlFor="aadharFrontPhoto">
                <Button size="small" component="span" variant="contained">
                  Choose file{" "}
                  {fileNames?.aadharFrontPhoto
                    ? `: ${fileNames?.aadharFrontPhoto}`
                    : ".."}
                </Button>
                <Input
                  accept="image/*"
                  onChange={onImageSelect}
                  name="aadharFrontPhoto"
                  id="aadharFrontPhoto"
                  type="file"
                />
              </label>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 210 }}>
              <label>Aadhar Back Photo</label>
              <label htmlFor="aadharBackPhoto">
                <Button size="small" component="span" variant="contained">
                  Choose file{" "}
                  {fileNames?.aadharBackPhoto
                    ? `: ${fileNames?.aadharBackPhoto}`
                    : ".."}
                </Button>
                <Input
                  accept="image/*"
                  onChange={onImageSelect}
                  name="aadharBackPhoto"
                  id="aadharBackPhoto"
                  type="file"
                />
              </label>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: 210 }} fullWidth required>
              <DatePicker
                label="Date of Birth"
                inputFormat="dd MMM yyyy"
                value={cookDetails?.dateOfBirth || null}
                disableMaskedInput
                maxDate={moment().toDate()}
                onChange={(value) => {
                  setCookDetails({
                    ...cookDetails,
                    dateOfBirth: value,
                  });
                  setChangeState({
                    ...changeState,
                    dateOfBirth: value,
                  });
                }}
                renderInput={(params) => {
                  return <TextField {...params} />;
                }}
              />
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
                name="whatsappNumber"
                sx={{ minWidth: 210 }}
                id="outlined-basic"
                label="Whatsapp Number"
                variant="outlined"
                onChange={handleDetailsChange}
                value={cookDetails?.whatsappNumber}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: 210 }} fullWidth required>
                <TextField
                  name="fathersName"
                  sx={{ minWidth: 210 }}
                  id="outlined-basic"
                  label="Father's Name"
                  required
                  variant="outlined"
                  onChange={handleDetailsChange}
                  value={cookDetails?.fathersName}
                />
                <FormHelperText>Required</FormHelperText>
              </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth required>
                <TextField
                  name="permanentAddressLine1"
                  fullWidth
                  id="outlined-basic"
                  label="Permanent Address Line 1"
                  required
                  variant="outlined"
                  onChange={handleDetailsChange}
                  value={cookDetails?.permanentAddressLine1}
                />
                <FormHelperText>Required</FormHelperText>
              </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="permanentAddressLine2"
              fullWidth
              id="outlined-basic"
              label="Permanent Address Line 2"
              variant="outlined"
              onChange={handleDetailsChange}
              value={cookDetails?.permanentAddressLine2}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: 210 }} fullWidth required>
              <TextField
                name="permanentAddressPinCode"
                sx={{ minWidth: 210 }}
                id="outlined-basic"
                label="Permanent Address Pincode"
                required
                variant="outlined"
                onChange={handleDetailsChange}
                value={cookDetails?.permanentAddressPinCode}
              />
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: 210 }} fullWidth required>
                <TextField
                  name="permanentAddressCity"
                  sx={{ minWidth: 210 }}
                  id="outlined-basic"
                  label="Permanent Address City"
                  required
                  variant="outlined"
                  onChange={handleDetailsChange}
                  value={cookDetails?.permanentAddressCity}
                />
                <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: 210 }} fullWidth required>
              <TextField
                name="permanentAddressState"
                sx={{ minWidth: 210 }}
                id="outlined-basic"
                label="Permanent Address State"
                required
                variant="outlined"
                onChange={handleDetailsChange}
                value={cookDetails?.permanentAddressState}
              />
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth required>
                <TextField
                  name="currentAddressLine1"
                  fullWidth
                  id="outlined-basic"
                  label="Current Address Line 1"
                  required
                  variant="outlined"
                  onChange={handleDetailsChange}
                  value={cookDetails?.currentAddressLine1}
                />
                <FormHelperText>Required</FormHelperText>
              </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="currentAddressLine2"
              fullWidth
              id="outlined-basic"
              label="Current Address Line 2"
              variant="outlined"
              onChange={handleDetailsChange}
              value={cookDetails?.currentAddressLine2}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: 210 }} fullWidth required>
              <TextField
                name="currentAddressPinCode"
                sx={{ minWidth: 210 }}
                id="outlined-basic"
                label="Current Address Pincode"
                required
                variant="outlined"
                onChange={handleDetailsChange}
                value={cookDetails?.currentAddressPinCode}
              />
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: 210 }} fullWidth required>
              <TextField
                name="currentAddressState"
                sx={{ minWidth: 210 }}
                id="outlined-basic"
                label="Current Address State"
                required
                variant="outlined"
                onChange={handleDetailsChange}
                value={cookDetails?.currentAddressState}
              />
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="emergencyContactName"
              sx={{ minWidth: 210 }}
              id="outlined-basic"
              label="Emergency Contact Name"
              variant="outlined"
              onChange={handleDetailsChange}
              value={cookDetails?.emergencyContactName}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="emergencyContactNumber"
              sx={{ minWidth: 210 }}
              id="outlined-basic"
              label="Emergency Contact Number"
              variant="outlined"
              onChange={handleDetailsChange}
              value={cookDetails?.emergencyContactNumber}
            />
            <FormControl sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-label" required>
                Source of Lead
              </InputLabel>
              <Select
                name="source"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cookDetails?.source || cookDetail.source}
                label="Source of Lead"
                onChange={handleDetailsChange}
              >
                {Object.values(NewVendorSource).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
          <FormControl sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-label">Interested in Mg?</InputLabel>
              <Select
                name="isMgCook"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Interested in Mg?"
                onChange={handleDetailsChange}
                defaultValue={
                  cookDetails?.isMgCook !== null
                    ? cookDetails?.isMgCook === true
                      ? YesNoEnum.YES
                      : YesNoEnum.NO
                    : undefined
                }
              >
                {Object.values(YesNoEnum).map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <GetSubSource source={cookDetails?.source} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="referralMobileNumber"
              sx={{ minWidth: 210 }}
              id="outlined-basic"
              label="Referral Mobile Number"
              variant="outlined"
              onChange={handleDetailsChange}
              value={cookDetails?.referralMobileNumber}
            />
            <FormControl sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-label">Campaign Name</InputLabel>
              <Select
                name="campaignName"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Campaign Name"
                onChange={handleDetailsChange}
                value={cookDetails?.campaignName}
              >
                <MenuItem value={"Lead From"}>Lead From</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ minWidth: 210 }}>
              <InputLabel id="demo-simple-select-label">T-Shirt Size</InputLabel>
              <Select
                name="tShirtSize"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="T-Shirt Size"
                onChange={(e: any) => {
                  setCookDetails({
                    ...cookDetails,
                    tShirtSize: e.target.value,
                  });
                  setChangeState({
                    ...changeState,
                    tShirtSize: TshirtNumberMapping[e.target.value],
                  });
                }}
                defaultValue={numberToShirtSizeMapping(cookDetails?.tShirtSize) as TshirtSizeEnum}
              >
                {Object.keys(TshirtSizeEnum).map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel>
                {`Do you know how to cook?`}
              </FormLabel>
              <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={cookDetails?.canCook === true ? 'true' : cookDetails?.canCook === false ? 'false' : null}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCookDetails({
                      ...cookDetails,
                      canCook: event.target.value === 'true',
                    });
                    setChangeState({
                      ...changeState,
                      canCook: event.target.value === 'true',
                    });
                  }}
              >
                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {!cookDetails?.canCook && (
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel>
                    {`Would you like to learn cooking?`}
                  </FormLabel>
                  <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={cookDetails?.wantToLearnCooking === true ? 'true' : cookDetails?.wantToLearnCooking === false ? 'false' : null}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setCookDetails({
                          ...cookDetails,
                          wantToLearnCooking: event.target.value === 'true',
                        });
                        setChangeState({
                          ...changeState,
                          wantToLearnCooking: event.target.value === 'true',
                        });
                      }}
                  >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
          )}
          <Grid item xs={12}>
            <PreviousWorkSelect
                cookDetail={cookDetails}
                onCallback={(data) => {
                  setCookDetails({
                    ...cookDetails,
                    previousWorkType: data,
                  });
                  setChangeState({
                    ...changeState,
                    previousWorkType: data,
                  });
                }}
            />
          </Grid>
          {cookDetails?.previousWorkType === 'Hotels' && (
            <Grid item xs={12}>
              <FormControl>
                <FormLabel>
                  {`Are you ready to work in home kitchen?`}
                </FormLabel>
                <RadioGroup
                    value={cookDetails?.openToHomeKitchens === true ? 'true' : cookDetails?.openToHomeKitchens === false ? 'false' : null}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCookDetails({
                        ...cookDetails,
                        openToHomeKitchens: event.target.value === 'true',
                      });
                      setChangeState({
                        ...changeState,
                        openToHomeKitchens: event.target.value === 'true',
                      });
                    }}
                >
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="tags-standard"
              value={cookDetails?.preferredCities||[]}
              options={cities.map((c:any)=>c?.name)}
              onChange={(e,v)=>{
                setCookDetails({
                ...cookDetails,
                preferredCities: v.length>0?v.map((city:any)=>city):['']
                });
                setChangeState({
                  ...changeState,
                  preferredCities: v.length>0?v.map((city:any)=>city):['']
                });}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Where do you want to work?"
                  placeholder="Write here..."
                />
              )}
            />
          </Grid>
          <Grid xs={12} item>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">{`Choose the cuisines you can cook?`}</FormLabel>
              <FormGroup>
                <FormControlLabel
                    control={
                      <Checkbox checked={cookDetails?.cuisinesCanCook?.includes('North Indian')} onChange={handleCheckCuisines} value={'North Indian'} name="North Indian" />
                    }
                    label="North Indian"
                />
                <FormControlLabel
                    control={
                      <Checkbox checked={cookDetails?.cuisinesCanCook?.includes('South Indian')} onChange={handleCheckCuisines} value={'South Indian'} name="South Indian" />
                    }
                    label="South Indian"
                />
                <FormControlLabel
                    control={
                      <Checkbox checked={cookDetails?.cuisinesCanCook?.includes('Chinese')} onChange={handleCheckCuisines} value={'Chinese'} name="Chinese" />
                    }
                    label="Chinese"
                />
                <FormControlLabel
                    control={
                      <Checkbox checked={cookDetails?.cuisinesCanCook?.includes('Mexican')} onChange={handleCheckCuisines} value={'Mexican'} name="Mexican" />
                    }
                    label="Mexican"
                />
                <FormControlLabel
                    control={
                      <Checkbox checked={cookDetails?.cuisinesCanCook?.includes('Continental')} onChange={handleCheckCuisines} value={'Continental'} name="Continental" />
                    }
                    label="Continental"
                />
                <FormControlLabel
                    control={
                      <Checkbox checked={cookDetails?.cuisinesCanCook?.includes('Thai')} onChange={handleCheckCuisines} value={'Thai'} name="Thai" />
                    }
                    label="Thai"
                />
                <FormControlLabel
                    control={
                      <Checkbox checked={cookDetails?.cuisinesCanCook?.includes('Japanese')} onChange={handleCheckCuisines} value={'Japanese'} name="Japanese" />
                    }
                    label="Japanese"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <CurrentCitySelect cookDetail={cookDetails} onCallback={(data) => {
              setCookDetails({
                ...cookDetails,
                ...data,
              });
              setChangeState({
                ...changeState,
                ...data,
              });
            }} />
          </Grid>
          <Grid item xs={6}>
            <TextField
                name="currentMonthlyEarnings"
                sx={{ minWidth: 210 }}
                id="outlined-basic"
                label="Current Monthly Earnings"
                variant="outlined"
                type={'number'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCookDetails({
                    ...cookDetails,
                    currentMonthlyEarnings: Number(event.target.value),
                  });
                  setChangeState({
                    ...changeState,
                    currentMonthlyEarnings: Number(event.target.value),
                  });
                }}
                value={cookDetails?.currentMonthlyEarnings}
            />
          </Grid>
        </Grid>
      </CkMuiDialog>
    </div>
  );
};

export default UpdateCookDetailsDialog;
